import React, {Component} from 'react';
import Loading from "./../../components/loading";
import APIService from './../../modules/apiService';
import Subheader from "../../components/subheader";
import locales_es from "../../locales/es";
import {
  DEFAULT_TIME_ZONE, HREF_PAGE_ADD_MEDIC, HREF_PAGE_HOME,
  HREF_PAGE_MEDICS, PARSE_TEL_INPUT_ONLY_COUNTRIES, USER_TYPE_MEDIC,
  USER_TYPE_SECRETARY
} from "../../models/constants";
import Helpers from "../../modules/helpers";
import AuthService from "../../modules/authService";
import Form from "../../components/form";
import DateTimeService from "../../modules/DateTimeService";
import intlTelInput from "intl-tel-input";
import ConfigService from "../../modules/configService";
import TimezoneService from "../../modules/timezoneService";
import AvatarImage from "../../components/avatarImage";

export default class AddMedicPage extends Component {

  constructor(props) {
    super(props);
    this.state = {
      userId: Number(props.match.params.userId),
      loading: true,
      prefix_name: '',
      name: '',
      lastname: '',
      email: '',
      email_repeat: '',
      loginMode: false,
      user_type: USER_TYPE_MEDIC,
      identificationOptions: [],
      genderOptions: [],
      timezoneOptions: [
        {value: 0, label: locales_es.loading},
      ],
      time_zone: DEFAULT_TIME_ZONE,
      redirect: window.URLSearchParams
        ? new window.URLSearchParams(this.props.location.search).get("redirect") : null,
      specialtyOptions: [],
      specialtyId: 0,
      medical_registration_number: '',
      full_profile_image: '',
    };

    this.api = new APIService();
    this.helpers = new Helpers();
    this.auth = new AuthService();
    this.dateTimeService = new DateTimeService();
    this.configService = new ConfigService();
    this.timezoneService = new TimezoneService();
  }

  componentDidMount() {
    if (this.auth.getLocalUserType() !== USER_TYPE_SECRETARY) {
      window.location.href = HREF_PAGE_HOME;
      return;
    }
    this.api.getIdentificationTypes().then(res => {
      this.setState({
        identificationOptions: res.data,
        identification_type_id: res.data[0].id
      })
    }).catch(err => {
      this.props.showMainModal(locales_es.errorModal.title, this.helpers.getErrorMsg(err));
    });
    this.api.getGenders().then(res => {
      this.setState({
        genderOptions: res.data,
        gender_id: res.data[0].id
      })
    }).catch(err => {
      this.props.showMainModal(locales_es.errorModal.title, this.helpers.getErrorMsg(err));
    });

    this.api.getSpecialties().then(res => {
      this.setState({
        specialtyOptions: res.data.map(specialty => {
          specialty.value = specialty.id;
          specialty.label = specialty.name;
          return specialty;
        }),
        specialtyId: res.data[0].id
      })
    }).catch(err => {
      this.props.showMainModal(locales_es.errorModal.title, this.helpers.getErrorMsg(err));
    });

    this.timezoneService.getRemoteParsedTimezones().then(res => {
      this.setState({
        timezoneOptions: res,
        time_zone: res.filter(tz => tz.value === DEFAULT_TIME_ZONE)[0]
      }, () => this.loadUser()) // Checks if user id exists and loads user data
    }).catch(err => {
      this.props.showMainModal(locales_es.errorModal.title, this.helpers.getErrorMsg(err));
    });
  }

  loadUser() {
    if (this.state.userId) {
      this.api.getMedicById(this.state.userId).then(res => {
        const medicData = res.data;
        const specialty = this.state.specialtyOptions.filter(tz => tz.value === medicData.specialty_id);
        this.setState({
          email: medicData.email,
          email_repeat: medicData.email,
          cellphone: medicData.cellphone,
          identification_type_id: medicData.identification_type_id,
          identification: medicData.identification,
          date_of_birth: this.dateTimeService.parseAPIStringToDate(medicData.date_of_birth),
          gender_id: medicData.gender_id,
          full_profile_image: medicData.full_profile_image,
          prefix_name: medicData.prefix_name,
          name: medicData.name,
          lastname: medicData.lastname,
          specialtyId: {
            'value': medicData.specialty_id,
            'label': specialty && specialty.length ? specialty[0].label : locales_es.notDefined,
          },
          medical_registration_number: medicData.medical_registration_number,
          description: medicData.description,
          facebook_url: medicData.facebook_url,
          twitter_url: medicData.twitter_url,
          instagram_url: medicData.instagram_url,
          linkedin_url: medicData.linkedin_url,
          website_url: medicData.website_url,
          public_email: medicData.public_email,
          public_phone: medicData.public_phone,
          time_zone: {
            'value': medicData.time_zone,
            'label': this.state.timezoneOptions.filter(tz => tz.value === medicData.time_zone)[0].label,
          },
        }, () => this.parseTelInputs());
      }).catch(err => {
        this.props.showMainModal(locales_es.errorModal.title, this.helpers.getErrorMsg(err));
      });
    } else {
      this.parseTelInputs();
    }
  }

  handleChange = state => ev => {
    this.setState({[state]: ev.target.value});
  };

  handleDateChange = state => value => {
    this.setState({[state]: value});
  };

  handleReactSelectChange = state => value => {
    this.setState({[state]: value});
  };

  parseTelInputs() {
    const itiRegex = /\+\d+/gm;
    if (itiRegex.test(this.state.cellphone)) {
      const cellphone = this.helpers.findDialCodeAndPhoneNumber(this.state.cellphone).phoneNumberWithoutDialCode;
      const countryCode = this.helpers.findDialCodeAndPhoneNumber(this.state.cellphone).countryCode;

      this.setState({
        cellphone,
      }, () => {
        this.processParseTelInputs(countryCode);
      });
    } else {
      this.processParseTelInputs();
    }
    this.setLoading(false);
  }

  processParseTelInputs(countryCode) {
    // TODO Improve
    setTimeout(() => {
      const input = document.querySelector(".cellphoneInput");
      intlTelInput(input, {
        // any initialisation options go here
        initialCountry: countryCode || 'AR',
        autoPlaceholder: 'off',
        preferredCountries: PARSE_TEL_INPUT_ONLY_COUNTRIES
      });
    }, 700);
  }

  validateForm() {
    let errors = 0;
    if (!this.state.name
      || !this.state.lastname
      || !this.state.email
      || !this.state.email_repeat
      || !this.state.cellphone
      || !this.state.identification_type_id
      || !this.state.identification
      || !this.state.date_of_birth
      || !this.state.gender_id
      || !this.state.specialtyId
    ) {
      errors += 1;
      this.props.showMainModal(locales_es.errorModal.title, locales_es.errorModal.completeAllFormFields);
    }

    if (this.state.email !== this.state.email_repeat) {
      errors += 1;
      this.props.showMainModal(locales_es.errorModal.title, locales_es.errorModal.emailsDontMatch);
    }

    if (!this.state.time_zone) {
      errors += 1;
      this.props.showMainModal(locales_es.errorModal.title, locales_es.errorModal.checkTimeonze);
    }


    return !errors;
  }

  send() {
    if (this.validateForm()) {
      this.setLoading(true);

      const objData = JSON.parse(JSON.stringify(this.state));

      const itiFlag = document.querySelector('.iti__selected-flag');
      if (itiFlag) {
        const itiRegex = /\+\d+/gm;
        if (itiRegex.test(itiFlag.title)) {
          const prefix = itiFlag.title.match(itiRegex)[0];
          objData.cellphone = prefix + ' ' + objData.cellphone;
        }
      }

      objData.date_of_birth = this.dateTimeService.parseStringDateToAPIStringDate(
        this.dateTimeService.parseDateToConventionalAPIString(this.state.date_of_birth)
      );

      objData.time_zone = this.state.time_zone.value;
      objData.specialty_id = this.state.specialtyId.value;

      delete objData.timezoneOptions;
      delete objData.specialtyOptions;

      this.configService.getLocalClinicData().then(res => {
        objData.clinic_id = res.id;

        if (this.state.userId) {
          this.api.putUsers(this.state.userId, objData).then((res) => {
            this.checkProfileImageUpload(res);
          }).catch(err => {
            this.props.showMainModal(locales_es.errorModal.title, this.helpers.getErrorMsg(err));
            this.setLoading(false);
          });
        } else {
          this.api.postMedic(objData).then((res) => {
            this.checkProfileImageUpload(res);
          }).catch(err => {
            this.props.showMainModal(locales_es.errorModal.title, this.helpers.getErrorMsg(err));
            this.setLoading(false);
          });
        }
      }).catch(err => {
        this.props.showMainModal(locales_es.errorModal.title, this.helpers.getErrorMsg(err));
      });
    }
  }

  checkProfileImageUpload(res) {
    if (this.state.full_profile_image_base64) {
      this.api.uploadMedicProfileImage(this.state.full_profile_image_base64, this.state.userId || res.data?.user?.id).then(() => {
        this.setLoading(false);
        this.props.showMainModal(locales_es.successModal.title, res.message);
        this.successLoginRedirect();
      }).catch(err => {
        this.props.showMainModal(locales_es.errorModal.title, this.helpers.getErrorMsg(err));
        this.setLoading(false);
      })
    } else {
      this.setLoading(false);
      this.successLoginRedirect();
    }
  }

  successLoginRedirect() {
    if (this.state.redirect) {
      window.location.href = this.state.redirect;
      return;
    }
    // this.props.history.replace(hrefDashboard);
    // this.props.history.replace(HREF_PAGE_MEDICS);
    this.props.history.replace('/');
  }

  setLoading(bool) {
    this.setState({
      loading: bool
    })
  }

  render() {

    const {full_profile_image} = this.state;

    const inputs = [
      {
        label: locales_es.prefixName,
        placeholder: locales_es.prefixNamePlaceholder,
        id: 1,
        state: 'prefix_name',
        value: this.state.prefix_name,
        type: 'text',
        required: false,
        wrapperCustomClassName: 'form-group col-md-3 pl-md-0 clear',
      }, {
        label: locales_es.name,
        placeholder: locales_es.name,
        id: 2,
        state: 'name',
        value: this.state.name,
        type: 'text',
        required: true,
        wrapperCustomClassName: 'form-group col-md-6 float-left pl-md-0',
      },
      {
        label: locales_es.lastname,
        placeholder: locales_es.lastname,
        id: 3,
        state: 'lastname',
        value: this.state.lastname,
        type: 'text',
        required: true,
        wrapperCustomClassName: 'form-group col-md-6 float-left pr-md-0',
      },
      {
        label: locales_es.email_address,
        placeholder: locales_es.email_address,
        id: 4,
        state: 'email',
        value: this.state.email,
        type: 'email',
        required: true,
        // disabled: !!this.state.userId,
        wrapperCustomClassName: 'form-group col-md-6 float-left pl-md-0',
      },
      {
        label: locales_es.repeatEmail,
        placeholder: locales_es.repeatEmail,
        id: 5,
        state: 'email_repeat',
        value: this.state.email_repeat,
        type: 'email',
        required: true,
        // disabled: !!this.state.userId,
        wrapperCustomClassName: 'form-group col-md-6 float-left pr-md-0',
      },
      {
        label: locales_es.phone_number,
        placeholder: locales_es.phone_number_example,
        id: 6,
        state: 'cellphone',
        value: this.state.cellphone,
        type: 'number',
        required: true,
        // disabled: !!this.state.userId,
        wrapperCustomClassName: 'form-group col-12 col-md-4 float-left pl-md-0 clear',
        customClassName: 'cellphoneInput',
      },
      {
        label: locales_es.identificationType,
        placeholder: locales_es.identificationType,
        id: 8,
        state: 'identification_type_id',
        value: this.state.identification_type_id,
        type: 'select',
        required: true,
        // disabled: !!this.state.userId,
        options: this.state.identificationOptions,
        wrapperCustomClassName: 'form-group col-4 col-md-3 float-left pl-md-0',
      },
      {
        label: locales_es.identification,
        placeholder: locales_es.number,
        id: 9,
        state: 'identification',
        value: this.state.identification,
        type: 'text',
        required: true,
        // disabled: !!this.state.userId,
        wrapperCustomClassName: 'form-group col-8 col-md-5 float-left pr-md-0',
      },
      {
        label: locales_es.date_of_birth,
        placeholder: locales_es.date_of_birth_mask,
        id: 10,
        state: 'date_of_birth',
        value: this.state.date_of_birth,
        type: 'date',
        required: false,
        // disabled: !!this.state.userId,
        wrapperCustomClassName: 'form-group col-md-6 float-left pl-md-0',
        maxDate: new window.Date().getTime(),
      },
      {
        label: locales_es.autoperceivedGender,
        placeholder: locales_es.autoperceivedGender,
        id: 11,
        state: 'gender_id',
        value: this.state.gender_id,
        type: 'select',
        required: true,
        // disabled: !!this.state.userId,
        options: this.state.genderOptions,
        wrapperCustomClassName: 'form-group col-md-6 float-left pr-md-0',
      },
      {
        label: locales_es.specialty,
        placeholder: locales_es.specialty,
        id: 12,
        state: 'specialtyId',
        value: this.state.specialtyId,
        type: 'react-select',
        required: true,
        options: this.state.specialtyOptions,
        wrapperCustomClassName: 'form-group col-md-6 float-left pl-md-0',
      },
      {
        label: locales_es.medicalRegistrationNumber,
        placeholder: locales_es.medicalRegistrationNumberPlaceholder,
        id: 13,
        state: 'medical_registration_number',
        value: this.state.medical_registration_number,
        type: 'text',
        required: false,
        wrapperCustomClassName: 'form-group col-md-6 float-left pr-md-0',
      },
      {
        label: locales_es.bio,
        placeholder: locales_es.bioPlaceholder,
        id: 14,
        state: 'description',
        value: this.state.description,
        type: 'textarea',
        required: false,
        wrapperCustomClassName: 'form-group pr-md-0',
      },
      {
        label: locales_es.facebook,
        placeholder: locales_es.facebook,
        id: 15,
        state: 'facebook_url',
        value: this.state.facebook_url,
        type: 'text',
        required: false,
        wrapperCustomClassName: 'form-group col-md-6 float-left pl-md-0',
      },
      {
        label: locales_es.twitter,
        placeholder: locales_es.twitter,
        id: 16,
        state: 'twitter_url',
        value: this.state.twitter_url,
        type: 'text',
        required: false,
        wrapperCustomClassName: 'form-group col-md-6 float-left pr-md-0',
      },
      {
        label: locales_es.instagram,
        placeholder: locales_es.instagram,
        id: 17,
        state: 'instagram_url',
        value: this.state.instagram_url,
        type: 'text',
        required: false,
        wrapperCustomClassName: 'form-group col-md-6 float-left pl-md-0',
      },
      {
        label: locales_es.linkedIn,
        placeholder: locales_es.linkedIn,
        id: 18,
        state: 'linkedin_url',
        value: this.state.linkedin_url,
        type: 'text',
        required: false,
        wrapperCustomClassName: 'form-group col-md-6 float-left pr-md-0',
      },
      {
        label: locales_es.website,
        placeholder: locales_es.website,
        id: 19,
        state: 'website_url',
        value: this.state.website_url,
        type: 'text',
        required: false,
        wrapperCustomClassName: 'form-group col-md-6 float-left pl-md-0',
      },
      {
        label: locales_es.publicEmail,
        placeholder: locales_es.publicEmail,
        id: 20,
        state: 'public_email',
        value: this.state.public_email,
        type: 'text',
        required: false,
        wrapperCustomClassName: 'form-group col-md-6 float-left pr-md-0',
      },
      {
        label: locales_es.publicPhone,
        placeholder: locales_es.publicPhone,
        id: 21,
        state: 'public_phone',
        value: this.state.public_phone,
        type: 'text',
        required: false,
        wrapperCustomClassName: 'form-group col-md-6 float-left pl-md-0',
      },
      {
        label: locales_es.timezone,
        placeholder: locales_es.timezone,
        id: 22,
        state: 'time_zone',
        value: this.state.time_zone,
        type: 'react-select',
        required: true,
        // disabled: !!this.state.userId,
        options: this.state.timezoneOptions,
        wrapperCustomClassName: 'form-group col-md-6 float-left',
      },
    ];

    const onFileSelect = (file) => {
      console.log(file);
      if (file) {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = () => {
          const base64 = reader.result;

          const url = URL.createObjectURL(file);
          this.setState({
            full_profile_image: url,
            full_profile_image_base64: base64,
            full_profile_image_file: file,
          })
        }
      }
    }

    return (
      <>
        {this.state.loading ? <Loading/> : ''}
        <Subheader breadcrumbs={[
          {
            name: locales_es.specialists,
            href: HREF_PAGE_MEDICS
          },
          {
            name: locales_es.addSpecialist,
            href: HREF_PAGE_ADD_MEDIC
          }
        ]}/>
        <div className="kt-grid kt-grid--desktop kt-grid--ver kt-grid--ver-desktop kt-app">
          <div className="kt-grid__item kt-grid__item--fluid kt-app__content">
            <Form
              styles="kt-form"
              inputs={inputs}
              handleChange={this.handleChange}
              handleDateChange={this.handleDateChange}
              handleReactSelectChange={this.handleReactSelectChange}
              onSubmit={() => this.send()}
              onSubmitButtonText={locales_es.send}
              secondaryButtonText={locales_es.cancel}
              onClickSecondaryButton={() => {
                this.props.history.goBack()
              }}
              showTerms={false}
              wrapper={true}
            >
              <>
                <div className="kt-portlet__head">
                  <div className="kt-portlet__head-label">
                    <h3 className="kt-portlet__head-title mb-3">{locales_es.registerNewSpecialist}</h3>
                  </div>
                </div>
                <div className="mb-3">
                  <AvatarImage profileImage={full_profile_image} enableUpload={true}
                               onFileSelect={onFileSelect}/>
                </div>
              </>
            </Form>
          </div>
        </div>
      </>
    )
  }
}
