import React, { Component } from 'react';
import {hrefDashboard, PLAN_TAGS} from "../../models/constants";
import AuthService from "../../modules/authService";
import Loading from "./../../components/loading";
import ConfigService from "../../modules/configService";
import APIService from "../../modules/apiService";
import Helpers from "../../modules/helpers";
import locales_es from "../../locales/es";
import Spinner from "../../components/spinner";

export default class PlansPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      plans: null,
      /// currentPlanTag: null,
      currentPlanTag: PLAN_TAGS.FREE,
      redirect: window.URLSearchParams
        ? new window.URLSearchParams(this.props.location.search).get("redirect") : null,
    };

    this.auth = new AuthService();
    this.api = new APIService();
    this.helpers = new Helpers();
    this.configService = new ConfigService();
  }

  componentWillMount() {
    this.checkUserStatus();
  }

  componentDidMount() {
    this.setLoading(true);

    this.api.getPlans().then(res => {
      const plans = res.data;

      this.api.getMyPlan().then(myPlanRes => {
        const currentPlan = myPlanRes.data;

        this.setState({
          plans,
          currentPlanTag: currentPlan ? currentPlan.tag : PLAN_TAGS.FREE, // Usamos el tag del plan actual
          loading: false
        });
      }).catch(() => {
        this.setState({
          plans,
          currentPlanTag: PLAN_TAGS.FREE,
          loading: false
        });
      });
    }).catch(err => {
      this.props.showMainModal(locales_es.errorModal.title, this.helpers.getErrorMsg(err));
      this.setLoading(false);
    });
  }

  async checkUserStatus() {
    const isLoggedIn = await this.auth.isLoggedUser();
    if (!isLoggedIn) {
      this.redirect();
    }
  }

  redirect() {
    if (this.state.redirect) {
      window.location.href = this.state.redirect;
      return;
    }
    window.location.href = hrefDashboard;
  }

  setLoading(bool) {
    this.setState({ loading: bool });
  }

  getPriceClass(plan, index) {
    const { currentPlanTag, plans } = this.state;

    if (plan.tag === currentPlanTag) {
      return 'text-warning';
    } else if (index === plans.findIndex(p => p.tag === currentPlanTag) + 1) {
      return 'text-success';
    } else {
      return 'text-info';
    }
  }

  getIconClass(plan) {
    switch (plan.tag) {
      case PLAN_TAGS.FREE:
        return 'flaticon-avatar text-info';
      case PLAN_TAGS.PRO:
        return 'flaticon-avatar text-success';
      case PLAN_TAGS.ENTERPRISE:
        return 'flaticon2-avatar';
      default:
        return 'flaticon-avatar';
    }
  }

  subscribeToPlan = (plan) => {
    this.props.showMainModal(`¿Suscribirse al plan ${plan.toUpperCase()}?`, 'Te llevaremos a la pasarela de suscripción para que puedas activar tu plan', null, [{
      label: locales_es.confirm,
      class: 'btn btn-success btn-elevate btn-pill btn-elevate-air',
      method: () => {
        this.confirmSubscription(plan);
      }
    }, {
      label: locales_es.cancel,
      class: 'btn btn-danger btn-elevate btn-pill btn-elevate-air',
    }])
  };

  confirmSubscription = (planTag) => {
    this.api.postPlanSubscribe(planTag).then((res) => {
      // this.props.showMainModal(locales_es.successModal.title, locales_es.successModal.message);
      if (res.data?.init_point) {
        window.open(res.data.init_point, '_self');
      } else {
        this.props.showMainModal(locales_es.successModal.title, res.message ?? locales_es.successModal.message);
      }
    }).catch(err => {
      this.props.showMainModal(locales_es.errorModal.title, this.helpers.getErrorMsg(err));
    });
  };

  unsubscribeFromPlan = (planTag) => {
    this.props.showMainModal(`¿Quiere desactivar el plan ${planTag}?`, 'La desuscripción puede demorar hasta 20 días hábiles en hacerse efectiva.', null, [{
      label: locales_es.unsubscribe,
      class: 'btn btn-warning btn-elevate btn-pill btn-elevate-air',
      method: () => {
        this.confirmSubscription(PLAN_TAGS.FREE);
      }
    }, {
      label: locales_es.cancel,
      class: 'btn btn-danger btn-elevate btn-pill btn-elevate-air',
    }])
  };

  handleButtonClick = (plan) => {
    const action = this.buttonActions[plan.tag] || this.buttonActions.default;
    action();
  };

  buttonActions = {
    [PLAN_TAGS.FREE]: () => this.subscribeToPlan(PLAN_TAGS.FREE),
    [PLAN_TAGS.PRO]: () => this.subscribeToPlan(PLAN_TAGS.PRO),
    [PLAN_TAGS.ENTERPRISE]: () => window.open('https://forms.gle/UJ15igr8fcCVgZX19', '_blank'),
    default: () => alert('Acción no definida para este plan')
  };

  renderButton(plan) {
    const { currentPlanTag } = this.state;

    if (plan.tag === currentPlanTag && currentPlanTag === PLAN_TAGS.FREE) {
      return (
        <button
          className="btn btn-outline-metal btn-pill btn-widest btn-taller btn-bold"
          onClick={() => this.handleButtonClick(plan)}
        >
          Tu plan actual
        </button>
      );
    }

    if (plan.tag === currentPlanTag) {
      return (
        <button
          className="btn btn-warning btn-pill btn-widest btn-taller btn-bold"
          onClick={() => this.unsubscribeFromPlan(plan.tag)}
        >
          Desuscribir
        </button>
      );
    }

    return (
      <button
        className="btn btn-brand btn-pill btn-widest btn-taller btn-bold"
        onClick={() => this.handleButtonClick(plan)}
      >
        Contratar
      </button>
    );
  }

  renderFeatures(features, previousPlanFeatures) {
    const repeatedFeatures = previousPlanFeatures?.map(f => f.name) || [];

    return (
      <ul className="pl-0">
        {previousPlanFeatures && (
          <li className="text-black-50 mt-4" style={{ listStyle: 'none' }}>
            <i className="flaticon2-check-mark text-success" />
            <strong className="d-block">
              Incluye todo lo que tiene el plan anterior, más:
            </strong>
          </li>
        )}
        {features
          .sort((a, b) => a.order - b.order)
          .filter(feature => !repeatedFeatures.includes(feature.name))
          .map(feature => (
            <li
              className="text-black-50 mt-4"
              style={{ listStyle: 'none' }}
              key={feature.id}
            >
              <i className="flaticon2-check-mark text-success" />
              <strong className="d-block">{feature.name}:</strong>{' '}
              {feature.description}
            </li>
          ))}
      </ul>
    );
  }

  renderPlans() {
    const { plans, currentPlanTag } = this.state;
    const columnSize = plans.length ? Math.floor(12 / plans.length) : 3;

    return (
      <div className="row row-no-padding">
        {plans.map((plan, index) => {
          const previousPlanFeatures = index > 0 ? plans[index - 1].features : null;

          return (
            <div key={plan.id} className={`col-lg-12 col-xl-${columnSize}`}>
              <div className={`kt-pricing-v1 kt-pricing-v1--${plan.tag}`}>
                <div className="kt-pricing-v1__header">
                  <div className="kt-iconbox kt-iconbox--no-hover">
                    <div className="kt-iconbox__icon">
                      <div className="kt-iconbox__icon-bg"></div>
                      <i className={this.getIconClass(plan)}></i>
                    </div>
                    <div className="kt-iconbox__title text-uppercase">
                      {plan.name}{' '}
                      {plan.tag === currentPlanTag && plan.tag !== PLAN_TAGS.FREE && (
                        <span>(Plan Actual)</span>
                      )}
                    </div>
                  </div>
                </div>
                <div className="kt-pricing-v1__body">
                  <div className="kt-pricing-v1__labels">
                    <div className="kt-pricing-v1__labels-item">
                      {plan.description}
                    </div>
                  </div>
                  <div className="kt-pricing-v1__content">
                    {this.renderFeatures(plan.features, previousPlanFeatures)}
                  </div>
                  <div className={`kt-pricing-v1__price ${this.getPriceClass(plan, index)} ${plan.tag === PLAN_TAGS.ENTERPRISE ? 'm-0' : ''}`}>
                    {plan.price_ars}
                    <span className="kt-pricing-v1__price-currency">${plan.tag === PLAN_TAGS.ENTERPRISE ? '/usuario' : ''}</span>
                  </div>
                  {plan.tag === PLAN_TAGS.ENTERPRISE && (
                    <div className={`kt-pricing-v1__price ${this.getPriceClass(plan, index)}`}>
                      <span className="kt-pricing-v1__price-currency kt-font-sm m-0">Mínimo 2 usuarios</span>
                    </div>
                  )}
                  <div className="kt-pricing-v1__button">
                    {this.renderButton(plan)}
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    );
  }

  render() {
    return (
      <>
        {this.state.loading ? <Loading /> : ''}
        <div className="kt-portlet">
          {this.state.plans ? this.renderPlans() : <Spinner />}
        </div>
      </>
    );
  }
}
